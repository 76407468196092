import * as React from 'react'
import * as _ from 'lodash'
import { Link, graphql } from 'gatsby'
import Octicon, { ArrowLeft, ArrowRight } from '@githubprimer/octicons-react'
import rehypeReact from 'rehype-react'

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: { HostTable }
}).Compiler

import IndexLayout from '../layouts'
import StyledPage from '../components/StyledPage'
import Container from '../components/Container'

import { HostTable } from '../components/HostTable/HostTable'
import { IPageContent, INewsPage } from '../typings'

interface PostTemplateProps {
  data: {
    post: INewsPage
  }
  pageContext: IPageContent
}

const PostTemplate: React.SFC<PostTemplateProps> = (props: PostTemplateProps) => {
  const { pageContext, data } = props
  const { previousPagePath, nextPagePath, previousItem, nextItem } = pageContext
  const { post } = data

  return (
    <IndexLayout>
      <StyledPage>
        <Container>
          <h3>{post.frontmatter.title}</h3>
          <div>
            Originally published at{' '}
            <a href={post.frontmatter.original} target="_blank">
              Media Bias Fact Check News
            </a>
          </div>
          <div>{renderAst(post.htmlAst)}</div>
          <div>
            <nav className="pagination" role="navigation">
              <ul className="pagination">
                {previousPagePath && (
                  <li className="page-item">
                    <Link to={previousPagePath} rel="prev" className="page-link">
                      <span className="page-arrow-left">
                        <Octicon icon={ArrowLeft} size="medium" />
                      </span>
                      <span className="page-link-text">{previousItem.node.frontmatter.title}</span>
                    </Link>
                  </li>
                )}
                {nextPagePath && (
                  <li className="page-item">
                    <Link to={nextPagePath} rel="next" className="page-link">
                      <span className="page-link-text">{nextItem.node.frontmatter.title}</span>
                      <span className="page-arrow-right">
                        <Octicon icon={ArrowRight} size="medium" />
                      </span>
                    </Link>
                  </li>
                )}
              </ul>
            </nav>
          </div>
        </Container>
      </StyledPage>
    </IndexLayout>
  )
}

export default PostTemplate

export const query = graphql`
  query($pageId: String!) {
    post: markdownRemark(id: { eq: $pageId }) {
      ...newsFields
    }
  }
`
