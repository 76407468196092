import * as React from 'react'
import * as _ from 'lodash'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter'

import './HostTable.scss'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'

interface IHostTableProps {}

interface IHostTableState {
  data: any[]
}

const defaultSorted = [
  {
    dataField: 'name',
    order: 'asc'
  }
]

const biases: any = {
  'mostly-center': 'Mostly Center (Left-Center, Least, Right-Center)',
  'left-leaning': 'Left Leaning (Left & Left-Center)',
  'right-leaning': 'Right Leaning (Right & Right-Center)',
  'center-only': 'Least Biased',
  'left-center-only': 'Left-Center (only)',
  'right-center-only': 'Right-Center (only)',
  'left-only': 'Left (only)',
  'right-only': 'Right (only)',
  'pro-science-only': 'Pro-Science',
  'fact-checking-only': 'Fact Checking'
}

export class HostTable extends React.Component<IHostTableProps, IHostTableState> {
  constructor(props: any) {
    super(props)

    this.state = {
      data: []
    }
  }

  componentDidMount() {
    fetch('https://raw.githubusercontent.com/drmikecrowe/mbfcext/master/docs/revised/hosts.json')
      .then(response => response.json())
      .then(data => this.setState({ data: _.values(data.urls) }))
  }

  response(resp_data: any) {
    const data: any[] = []
    let id = 0
    _.each(resp_data.urls, row => {
      if (!row.labels || row.labels.length === 0) return
      data.push({
        id: `id-${id++}`,
        name: (
          <a target="_blank" href="${row.url}">
            {row.name}
          </a>
        ),
        labels: row.labels, //bias: _.startCase(row.labels[0].replace(/-/g, ' ').replace(/ only/g, '')).replace(/ /g, '-'),
        Score: row.Score
      })
    })
    return data
  }

  render() {
    const { data } = this.state
    const columns: any[] = [
      {
        dataField: 'name',
        text: 'Site',
        sort: true,
        filter: textFilter(),
        formatter: function(cell: any, info: any) {
          return (
            <div>
              <a href={info.url} target="_blank">
                {cell} &nbsp;
              </a>
              <span className="smalldetails">
                (
                <a href={info.mbfc_url} target="_blank">
                  MBFC Details
                </a>
                )
              </span>
            </div>
          )
        }
      },
      {
        dataField: 'labels',
        text: 'Bias(s)',
        formatter: function(cell: any) {
          return (
            <div>
              {cell.map((label: string, i: number) => (
                <>
                  <span className="nobreak">{biases[label]}</span>
                  {i < cell.length && <br />}
                </>
              ))}
            </div>
          )
        },
        filter: selectFilter({
          options: biases,
          withoutEmptyOption: false,
          onFilter: (filterVal: any) => {
            return data.filter(function(e: any) {
              return e.labels.indexOf(filterVal) > -1
            })
          }
        })
      },
      {
        dataField: 'Score',
        text: 'Popularity',
        align: 'right',
        sort: true,
        headerStyle: {
          width: '20%',
          'text-align': 'right'
        },
        formatter: function(cell: any) {
          return <div>{Math.round(cell * 100) + '%'}</div>
        }
      }
    ]
    return (
      <div className="row">
        <div className="col-sm-12">
          <BootstrapTable
            bootstrap4
            striped
            hover
            condensed
            keyField="id"
            data={this.state.data}
            columns={columns}
            defaultSorted={defaultSorted}
            pagination={paginationFactory()}
            filter={filterFactory()}
          />
        </div>
      </div>
    )
  }
}
